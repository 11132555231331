import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";

const LeadershipCoachingPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        heroImg: file(relativePath: { eq: "17.jpg" }) {
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        catherine: file(
          relativePath: { eq: "testimonials/catherine-clifford.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
        andy: file(relativePath: { eq: "testimonials/andy-collins.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
        peter: file(relativePath: { eq: "testimonials/Peter Hill.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
      }
    `
  );
  const siteUrl = data.site.siteMetadata.siteUrl;
  const schemaImage = data.heroImg.publicURL;
  const title = "Leadership Coaching in London";
  const description = "";
  const heroImage = data.heroImg.childImageSharp.fluid;
  const catherine = data.catherine.childImageSharp.gatsbyImageData;
  const peter = data.peter.childImageSharp.gatsbyImageData;
  const andy = data.andy.childImageSharp.gatsbyImageData;
  const ogImg = `${siteUrl}${schemaImage}`;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Leadership Coaching in London",
        item: {
          url: `${siteUrl}/leadership-coaching`,
          id: `${siteUrl}/leadership-coaching`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={title}
        description={description}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/leadership-coaching`,
          title: `${title}`,
          description: `${description}`,
          images: [
            {
              url: `${ogImg}`,
              width: 1920,
              height: 1080,
              alt: "Leadership Coaching in London",
            },
          ],
        }}
      />
      <Hero
        isDarken={true}
        backgroundImage={heroImage}
        title="Leadership coaching London"
        description1=""
        description2=""
      />
      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <h3 className="text-primary pb-3">
                Do you want to take your leadership to the next level?
              </h3>
              <p>
                Leadership skills are a crucial part of being a successful
                manager or entrepreneur. You want to motivate both yourself and
                your team to improve productivity, increase sales and enhance
                overall performance. Developing these skills is essential for
                both personal and small business or corporate growth. Without
                effective leadership, organisations both small and large will
                fail to thrive.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-5">
        <Container>
          <Row>
            <Col>
              <StaticImage
                className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
                src="../../images/diagram.jpg"
              />
              <h3 className="text-primary pb-3">
                What would life look like if you were a better leader?
              </h3>

              <ul className="pl-4">
                <li> Could you lead your team with greater confidence?</li>
                <li>What would your small business or career look like?</li>
                <li>Would money become a problem of the past?</li>
                <li>Could you complete your work with enthusiasm?</li>
                <li>
                  How could you impact your life and the lives of your team?
                </li>
                <li>What future would you create for your family?</li>
                <li>Would you have a better work/life balance?</li>
              </ul>
              <p>
                No individual ever created a significant improvement in their
                career or small business without becoming a better leader. But
                that can't happen overnight. So how can you begin making
                positive changes and start to make your vision a reality?
              </p>
              <p>
                You’ll need to develop the mindset and skill set and a plan that
                will take you to the next level.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white pb-5">
        <Container>
          <Row>
            <Col>
              {" "}
              <div className="iframe-container-new mb-4">
                <iframe
                  loading="lazy"
                  className="responsive-iframe"
                  src="https://www.youtube.com/embed/UYc6P9U5lVA"
                  title="Testimonial | Jason Cornes"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-primary text-white py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">What’s holding you back?</h2>
              <ul className="pl-4">
                <li>
                  Do you struggle with effective delegation, time-management or
                  motivation of self and team?
                </li>
                <li>Are you feeling stuck?</li>
                <li>Do you need to explore how to be a better communicator?</li>
                <li>
                  Does ‘Imposter Syndrome’ creep in and there are moments you
                  doubt you have what it takes to be an effective leader?
                </li>
                <li>Would you like to be better at managing change?</li>
                <li>Are you saying ‘Yes’ when you want to say ‘no’?</li>
                <li>Do you set and maintain boundaries?</li>
              </ul>
              <p>
                Are you jeopardising our own success by doing things in the same
                old way but expecting different results? We get so caught up in
                our day to day life and career, that we forget to take a step
                back and reflect and consider new ways of doing things.
              </p>
              <p>
                Instead of building our leadership skills, we fall into the trap
                of doubting ourselves and become frustrated.
              </p>
              <p>"Do I need a career change?"</p>
              <p>
                “Would my small business organisation be more profitable if I
                were a better leader?
              </p>
              <p>
                "Do I need more professional skills or better play to my
                strengths?"
              </p>
              <p>
                "Why aren't I as good as my competitors or the other senior
                leaders in my organisation?"
              </p>
              <p>
                We compare ourselves to others instead of focusing on what's
                most important: our own personal development. The learning
                pathway will vary from one manager to another depending on
                personality, leadership style, strengths and weaknesses, the
                type of small business or organisation, job title and so on. The
                goal however is similar. To play to your strengths and provide
                the insights and skills you need to become the best version of
                yourself in your career or business... and in the process, a
                better leader.
              </p>
              <p>
                So, how do you know what personal development path to follow?
              </p>
              <p>
                You work with someone like me, a professional development career
                coach. With a bespoke coaching programme to meet your
                circumstances and requirements, I can provide you with the
                support and guidance you need to achieve your goals, improve
                your performance and become the best leader you can be. As
                someone who has been through the trials and tribulations of
                entrepreneurship and invested in coaching for myself, I
                understand just how a coaching journey can help you achieve
                everything you've ever dreamt of.
              </p>
            </Col>
          </Row>
          <Row className="text-center pt-4">
            <Col>
              <Button
                variant="outline-light"
                size="lg"
                as={Link}
                to="/contact-us"
                className="cta-btn cta-btn-gtm"
              >
                Book a discovery meeting
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary pb-3">
                Leadership coaching in London designed for you
              </h2>
              <p>
                Whether you’ve started your own business, work as an executive
                or want to take your career to the next level, the right coach
                can help you reach your goals more effectively.{" "}
              </p>
              <p>
                The reality is, being a high performer is never going to be
                easy, especially in the London environment. The daily challenges
                of an executive and leaders in general can be extremely tiring,
                and that's without general life to contend with. You're expected
                to be determined, an excellent communicator, bring passion and
                meet deadlines and help move things forward on a daily basis.
                But how can you lead from the front without burning out?
              </p>

              <p>
                This is where my coaching skills will help. With a range of
                coaching packages available, I help business leaders, executives
                and business owners make long lasting positive change. My
                sessions are designed to help you focus on what matters to you
                whilst developing the skill set needed for a successful career.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Row>
            <Col>
              <StaticImage
                className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
                src="../../images/11.jpg"
              />
              <h2 className="text-primary pb-3">
                What do leadership coaches in London do?
              </h2>

              <p>
                Executive coaching doesn't mean I'll hold your hand to the top.
                No great coach can do that for you. As an executive coach, my
                job is to provide you with guidance in my coaching sessions to
                discover the way forward that suits you. Agree action points and
                hold you accountable step by step. This process involves helping
                you build the leadership skills you need to progress in both
                your career and personal life. It will involve listening, asking
                questions, offering new ideas, helping you gain clarity of your
                vision and to build a leadership style that plays to your
                strengths.
              </p>
              <h3 className="text-primary pb-2">Maximising your confidence</h3>
              <p>
                All leaders have a certain confidence about them. They live life
                to the fullest, motivate their peers and face challenges
                head-on. As an executive coach, I can help you develop
                leadership skills that will improve your confidence and thereby
                boost you to reach your full potential.
              </p>
              <p>
                From previous coaching conversations with clients, I've seen an
                improvement in confidence almost instantly. I've seen executives
                throughout the UK change the way they live their lives, engaging
                everyday challenges for greater passion and a process in place
                to achieve the career they want
              </p>
              <h3 className="text-primary  pt-4 pb-2">
                Career coaching made easy
              </h3>
              <p>
                As a career coach I make it my responsibility to help you get
                where you want in life. My coaching programme will be fully
                tailored to your needs, focusing on where you are with your
                career or small business and where you see yourself one or
                several years from now. Focusing on developing skills such as
                communication and confidence, I can make sure your skill set is
                suitable for any executive role, should you make a career change
                down the line.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-tertiary text-white  py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">
                What my coaching skills bring to the table.
              </h2>
              <p>
                Based in London and working on a one to one basis with clients
                in the Brighton to Greater London corridor and UK wide, I’ve
                helped hundreds of business owners, leaders and executives think
                differently, do things differently and be more productive and
                happy in their work.
              </p>
              <p>
                By combining my business qualifications (DMS & MBA), 5 years of
                counselling and psychotherapy training (Person Centred and
                Transactional Analysis) and getting on for four decades in the
                business world, I can work with you to align your small
                business, career and personal goals to help you get to where you
                want to be.
              </p>
              <p>
                Although there are many coaches out there, I truly believe there
                are very few that offer such a tailored coaching approach to the
                needs of each individual client as I do.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary pb-3">Client Testimonials</h2>
            </Col>
          </Row>

          <HorizontalTestimonial
            name="Andy Collins. Vostel Ltd"
            review="After an initial discovery meeting with Jason I immediately knew he was someone I could work with. His ongoing guidance and support is superb. I couldn’t recommend him highly enough."
            linkedIn="https://www.linkedin.com/in/andy-collins-2b178617/"
            profilePic={andy}
          />

          <HorizontalTestimonial
            name="Catherine Clifford. Managing Partner. Best Western Landsdowne Hotel."
            review="I met Jason at a networking event and straight away I found him to be very easy to talk to and I felt immediately at ease with him. I booked a session with him and I can honestly say that now, after several sessions, I have truly found a way forward for myself and my business. After a simple question about what it was that I actually wanted, it opened the flood gates to a huge amount of possibility that was there but I hadn’t had the courage to tap into. The coaching really helped me to find perspective and clear clouds that were thick and heavy in my mind about tackling challenges and moving forward . It’s fair to say that the clarity and confidence that I have gained from Jason’s business coaching has been invaluable. Thank you Jason!!"
            linkedIn="https://www.linkedin.com/in/catherineclifford72/"
            profilePic={catherine}
          />
          <HorizontalTestimonial
            name="Peter Hill. Dominic Hill Associates Ltd. Chartered Accountants."
            review="Try some learning about YOU! I set up Dominic Hill Chartered Accountants in 1992. I understand what it is like to be a small business because I am one. Sometimes you need someone outside your business with no pre-conceived ideas to open your mind to find out what you really want to achieve for yourself and also for your business and in that order. Once you have recognised what you want to achieve you can reset the destination in your life sat nav and set out in that direction. You shouldn’t and can’t re-set the sat nav without stopping to breath. Jason gives you the room to breath, think and then….. move Jason has been really motivating and he makes me smile. I really enjoy the meetings. I thoroughly recommend that you start with a 3 month program because like learning anything you need at least that span of time to really start to open your eyes. Why should learning about YOU be any different?"
            profilePic={peter}
            showLinkedIn="d-none"
          />
        </Container>
      </section>

      <section className="bg-secondary text-white py-5">
        <Container>
          <Row>
            <Col>
              <h3 className="pb-3">
                Ready to take your leadership to the next level?
              </h3>
              <p>
                As a UK executive or small business owner, the route to become a
                better business leader is through my services. As your career
                coach, I can help you on your professional development journey,
                focusing on the skills you need for career success and holding
                you accountable each step of the way.
              </p>
              <p>
                Each journey with me will help you push yourself to new limits,
                discover new things about yourself and build new leadership
                skills in the process.
              </p>
              <p>
                Let’s chat over an initial coaching and mentoring discovery
                meeting. No hard sell here. Just a discussion to see if we are a
                good fit and I’ll suggest how I can best help you. I look
                forward to meeting you!
              </p>
            </Col>
          </Row>
          <Row className="text-center pt-4">
            <Col>
              <Button
                variant="outline-light"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Get in Touch
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
    </Layout>
  );
};
export default LeadershipCoachingPage;
